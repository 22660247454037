import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro'
import { GatsbyImage } from "gatsby-plugin-image";
import { Section, Container } from '../../styles/common/Layout';
import ClientsListening from '../feature/clients/Clients';


export default function ClientsSection() {
  return (
    <>
      <Section id="section__clients" NoPadding>
        <ClientsListening />
      </Section>
    </>
  )
}