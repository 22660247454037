import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import tw from 'twin.macro'
import useIsInViewport from 'use-is-in-viewport';
import { Container, Section } from '../../styles/common/Layout';
import WordsSwitch from '../layout/ui/wordSwitch';
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const H1 = tw.h1`
  font-thin
  tracking-wide
  leading-loose
  subpixel-antialiased
  mb-6
  text-lg
  md:text-2xl
  lg:mb-12
  lg:text-4xl
`;

const SPAN = tw.span`
  text-2xl
  lg:text-4xl font-bold
  mb-4
`;

const P = tw.p`
  leading-relaxed mb-3
`;

const ShowcaseMockup = styled(GatsbyImage)`
  picture > img{
    height: auto !important;
  };
  > img {
    height: auto !important;
  }
`

const StyledWrapper = tw.div`
  flex flex-col lg:flex-row
`;

const StyledSectionLeft = tw.div`
  order-1
  w-full
  p-0
  flex
  flex-col
  justify-end
  text-left
  md:p-6
  lg:pr-28
  lg:w-6/12
`;

const StyledSectionRight = tw.div`
  order-2 py-6 w-full lg:w-6/12
`;

export default function MeSection() {
  const [isInViewport, targetRef] = useIsInViewport();

  return (<>
    <Section id="section__me">
      <Container>
        <StyledWrapper>
          <StyledSectionLeft>
            <H1>
              Tach auch! Ich bin der Marek. <br />
              <SPAN>Webentwickler</SPAN> <br />
              und Tourmanager aus dem Ruhrpott.
            </H1>
            <P>
              Als digitaler Nomade bin ich überall zuhause, wo mich das Tourleben hinträgt und mein Macbook und ich ein funktionierendes WLAN Signal finden.<br />
              Egal wie laut und wild es um mich herum wird (und das wird es im Metal oft) - ich behalte
stets den Überblick und bringe zuverlässig Struktur und Ordnung ins Chaos.</P>
            <P> Für meine Jungs und Mädels auf Tour genauso wie für dich und dein Projekt:<br />Ich sorge dafür, dass alles nach
Plan läuft und <strong>du bekommst</strong></P> <WordsSwitch />

            <P>
              Von einer einfachen <strong>Landingpage</strong> über deine individuelle <strong>Web App</strong> bis hin zum komplexen <strong>E-Commerce Shop</strong> – du brauchst eine Lösung? Ich kümmere mich darum!
            </P>
          </StyledSectionLeft>
          <StyledSectionRight>
            <StaticImage
              src="../../assets/images/me_1.jpg"
              alt="Marek Maras"
              imgStyle={{ borderRadius: "5px", objectFit: 'contain' }}
              placeholder="tracedSVG"
            />
          </StyledSectionRight>
        </StyledWrapper>
      </Container>
    </Section>
  </>
  )
}
