import styled from 'styled-components';
import tw from 'twin.macro'
import React from 'react'
import ClientCommunicaite from './ClientCommunicaite'
import ClientKulturgesichter from './ClientKulturgesichter';
import ClientDumpe from './ClientDumpe';

import useIsInViewport from 'use-is-in-viewport'
import { Tag, TagWrapper } from '../../../styles/common/Elements';
import { Container, Section } from '../../../styles/common/Layout';
import { H1, H1Content, P } from '../../../styles/common/Typo';

import Swiper from 'react-id-swiper';

const StyledWrapper = tw.div`
  flex
  flex-col
  md:flex-row
  md:min-h-1/2
`;

const StyledSectionLeft = tw.div`
  py-12
  px-6
  w-full
  md:p-12
  md:w-1/2
  md:flex
  md:justify-center
  md:flex-col
`;

const StyledSectionRight = tw.div`
  py-0
  px-0
  md:py-12
  w-full
  h-full
  md:w-1/2
`;

export default function ClientsListening() {
  const [isInViewport, targetRef] = useIsInViewport();
  const params = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }

  return (
    <>
      <Container ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>
        <StyledWrapper>
          <StyledSectionLeft>
            <H1>Was ich bisher so gemacht habe? <br />Riskier mal einen Blick.</H1>
            <P>Erzählen kann ich dir ja viel, wenn der Tag lang ist. Also, genug der Worte. Schau dich einfach
mal um und überzeuge dich selber von meiner Arbeit.</P>
          </StyledSectionLeft>
          <StyledSectionRight>
            <Swiper {...params}>
              <div><ClientKulturgesichter /></div>
              <div><ClientCommunicaite /></div>
              <div><ClientDumpe /></div>
            </Swiper>
          </StyledSectionRight>
        </StyledWrapper>
      </Container >
    </>
  )
}
