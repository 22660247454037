import { Link } from 'gatsby';
import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro'
import { Container, Section } from '../../styles/common/Layout';
import { H1 } from '../../styles/common/Typo';
import { faMobileAlt, faPaperPlane, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIsInViewport from 'use-is-in-viewport';

const P = tw.p`
  my-6
`;

const A = styled.a`
  ${tw`transition-all font-bold`}
  max-width: max-content;
  display: block;
  span:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 0px;
    transition: width .5s;
  }
  span:hover::after {
    width: 100%;
  }
`;

const ModLink = styled(Link)`
  ${tw`transition-all font-bold`}
  max-width: max-content;
  display: inline-block;
  span:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 0px;
    transition: width .5s;
  }
  span:hover::after {
    width: 100%;
  }
`;

const FlexItem = tw.div`
  flex-1
`

const LI = tw.li`
  flex flex-row items-center
`

const ContactCircle = styled.div`
  ${tw`bg-gray-500 transition-opacity opacity-50 group-hover:opacity-100 h-12 w-12 flex items-center justify-center mr-4`}
  transition: opacity 0.75s;
  border-radius: 3px;
`

const ContactContent = styled.div`
  ${tw`transition-opacity opacity-50 group-hover:opacity-100`}
`

export default function ContactSectionNew() {
  const [isInViewport, targetRef] = useIsInViewport();

  return (
    <>
      <Section id="section__contact" Color='White'>
        <Container>
          <StyledWrapper>
            <StyledSectionLeft>
              <H1 ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>Zuverlässig und kreativ. <br />Programmierer von Herzen.</H1>
              <P ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>
                Du brauchst eine Website? Eine App?<br />
                Einen Programmierer der richtig Bock hat?
              </P>
              <P ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>
                Dann hau in die Tasten und schreib mir, oder ruf einfach mal durch.
                Ich freu mich drauf.
              </P>
              <A ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'} title="Marek's E-Mail" href="mailto: mail@marek-maras.de" rel="noreferrer"><span>mail@marek-maras.de</span></A>
            </StyledSectionLeft>
            <StyledSectionRight>
              <H1 ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>Kontaktmöglichkeiten</H1>
              <P ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>Lass uns gerne über deine Projektwünsche reden.</P>
              <ul ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>
                <LI className="group">
                  <ContactCircle>
                    <FontAwesomeIcon style={{ color: 'white' }} icon={faKeyboard} size="lg" />
                  </ContactCircle>
                  <ContactContent>
                    <ModLink to="/kontakt"><span>Zum Kontaktformular</span></ModLink>
                  </ContactContent>
                </LI>
                <LI className="group">
                  <ContactCircle>
                    <FontAwesomeIcon style={{ color: 'white' }} icon={faPaperPlane} size="lg" />
                  </ContactCircle>
                  <ContactContent>
                    <A title="Marek's E-Mail" href="mailto: mail@marek-maras.de" rel="noreferrer"><span>mail@marek-maras.de</span></A>
                  </ContactContent>
                </LI>
                <LI className="group">
                  <ContactCircle>
                    <FontAwesomeIcon style={{ color: 'white' }} icon={faMobileAlt} size="lg" />
                  </ContactCircle>
                  <ContactContent>
                    <A title="Marek Marek Mobile" href="tel:+4917610271561" rel="noreferrer"><span>0176 / 10 27 15 61</span></A>
                  </ContactContent>
                </LI>
              </ul>
            </StyledSectionRight>
          </StyledWrapper>
        </Container>
      </Section>
    </>
  )
}

const StyledWrapper = tw.div`
  flex
  flex-col
  md:flex-row
`;

const StyledSectionLeft = tw.div`
  p-0
  md:p-6
  w-full
  md:w-6/12
`;

const StyledSectionRight = tw.div`
  p-0
  pt-6
  md:p-6
  md:pl-20
  w-full
  md:w-6/12
  md:block
`;