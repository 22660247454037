import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useTransition, config } from 'react-spring';
import tw from 'twin.macro';

/**
 * Inspired and got from https://fidalgo.dev/
 */

const WORDS = [
  { id: 0, text: 'was du brauchst' },
  { id: 1, text: 'wann du es brauchst' },
  { id: 2, text: 'wie du es brauchst' },
];

const Wrapper = styled.div`
  ${tw`relative inline-block text-2xl italic text-left font-bold transition-colors duration-200 ease-out mb-3`}
  width: 265px;

  & span {
    text-align: left;
    width: 100%;
  }
`;

const WordsSwitch = () => {
  // Subtitle keywords loop
  const [index, setIndex] = useState(0);
  const wordsTransition = useTransition(WORDS[index], span => span.id, {
    config: config.stiff,
    delay: 450,
    duration: 100,
    from: {
      opacity: 0,
      transform: 'translateY(10px)',
      position: 'absolute',
      top: 0,
      left: 0,
      margin: 0,
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
  });
  useEffect(() => {
    const interval = setInterval(
      () => setIndex(current => (current + 1) % WORDS.length),
      3500
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <i style={{ visibility: 'hidden' }}>was du brauchst</i>
      {wordsTransition.map(({ item, props, key }) => (
        <animated.span key={key} style={props}>
          {item.text}
        </animated.span>
      ))}
    </Wrapper>
  );
};

export default WordsSwitch;