import { useStaticQuery } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro'
import { H1, H1Content, P, A } from '../../../styles/common/Typo';
import { Tag, TagWrapper, CategoryWrapper } from '../../../styles/common/Elements';

const BackgroundWrapper = tw.div`
  relative px-12 md:p-20 w-full min-h-1/2 flex justify-center flex-col md:flex-col overflow-hidden
`
const TitleStyle = tw.h2`
  text-2xl
  lg:text-3xl
  font-bold
  mb-4
`

export default function ClientKulturgesichter() {
  return (
    <>
      <BackgroundWrapper>
        <TitleStyle>KulturgesichterNRW</TitleStyle>
        <CategoryWrapper>
          {['React', 'Gatsby', 'GraphQL', 'Sanity.io', 'Styled Components', 'Netlify'].map((tagName, i) => {
            return <Tag key={i}>{tagName}</Tag>
          })}
        </CategoryWrapper>
        <A className="mt-2" href="https://www.kulturgesichternrw.de/" rel="noreferrer noopener"><span>www.kulturgesichternrw.de</span></A>
      </BackgroundWrapper>
    </>
  )
}
