import React from 'react'
import tw from 'twin.macro';
import useIsInViewport from 'use-is-in-viewport'
import { Tag, TagWrapper } from '../../styles/common/Elements';
import { Container, Section } from '../../styles/common/Layout';
import { H1, H1Content, P } from '../../styles/common/Typo';

export default function SkillsSection() {
  const [isInViewport, targetRef] = useIsInViewport();
  return (
    <>
      <Section id="section__skills" Color='White'>
        <Container ref={targetRef} className={isInViewport ? 'is-visible' : 'is-hidden'}>
          <StyledWrapper>
            <StyledSectionLeft>
              <H1>Frontend Entwickler mit Leib und Seele. <br />Und auch im Backend zuhause.</H1>
              <P>Ich bin der nette Typ von nebenan, der dir dabei hilft deine <strong>Website</strong> an den Start oder deinen
              <strong> Online-Shop</strong> in Form zu bringen. Und nicht nur das. Du hast noch andere Wünsche? Ich
programmiere dir alles was man so auf einer Website sehen und klicken kann. Komm doch
mal zu mir rüber, auf einen digitalen Pott Kaffee, und wir quatschen über dein Projekt.</P>
            </StyledSectionLeft>
            <StyledSectionRight>
              <TagWrapper>
                <Tag>Javascript ES6 +</Tag>
                <Tag>React</Tag>
                <Tag>Gatsby</Tag>
                <Tag>GraphQL</Tag>
                <Tag>Node</Tag>
                <Tag>Git</Tag>
                <Tag>CSS</Tag>
                <Tag>SCSS</Tag>
                <Tag>SASS</Tag>
                <Tag>Styled Components</Tag>
                <Tag>Tailwind</Tag>
                <Tag>PHP</Tag>
                <Tag>Laravel</Tag>
                <Tag>MySql</Tag>
                <Tag>SQL</Tag>
                <Tag>REST API</Tag>
                <Tag>MongoDB</Tag>
                <Tag>Shopify</Tag>
                <Tag>Wordpress</Tag>
                <Tag>WooCommerce</Tag>
                <Tag>Pagespeed</Tag>
              </TagWrapper>
            </StyledSectionRight>
          </StyledWrapper>
        </Container>
      </Section>
    </>
  )
}

const StyledWrapper = tw.div`
  flex
  flex-col
  md:flex-row
`;

const StyledSectionLeft = tw.div`
  p-0
  pt-6
  w-full
  md:p-6
  md:w-1/2
  md:block
`;

const StyledSectionRight = tw.div`
  p-0
  w-full
  flex
  md:p-6
  md:w-1/2
`;