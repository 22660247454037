import React from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { LocaleContext } from '../components/layout/layout';
import { SEO } from '../components';
import { Container, Section } from '../styles/common/Layout';
import MeSection from '../components/Sections/Me';
import SkillsSection from '../components/Sections/Skills';
import ContactSectionNew from '../components/Sections/ContactNew';
import website from '../../config/website';
import ClientsSection from '../components/Sections/Clients';

const IndexPage = ({ data, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      {/* <SEO title="Startseite • Marek Maras Webentwicklung aus'm Ruhrpott" /> */}
      <SEO
        title={`${i18n.pageIndex} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale} />
      <MeSection />
      <SkillsSection />
      <ClientsSection />
      <ContactSectionNew />
    </>
  )
};

export const query = graphql`query HomePageQuery {
  logo: file(relativePath: {eq: "images/logo_big.png"}) {
    childImageSharp {
      gatsbyImageData(height: 235, quality: 80, placeholder: TRACED_SVG, layout: FIXED)
    }
  }
}
`

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}